footer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  width: 100vw;
  margin: 0;
  padding: 0;
  background-color: black;
  height: auto;
}
footer img {
  height: 4vw;
  margin: 2vw;
  filter: invert(0) sepia(0) saturate(0) hue-rotate(0) brightness(200%) contrast(100%);
}
footer span {
  text-align: center;
  width: 20vw;
  font-size: 2vw;
  color: white;
}

@media (max-width: 899px) {
  footer {
    height: 30vw !important;
    justify-content: center;
    padding: 5vw 0;
  }
  footer img {
    height: 8vw;
    margin: 2vw;
  }
}