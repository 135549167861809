body {
  background-color: #112038;
}

#background-video {
  display: block;
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}