header {
  display: flex;
  justify-content: space-between;
}

#wrap {
  display: block;
  width: 100%;
  text-align: center;
  transition-duration: 0.5s;
  align-items: center;
}

nav {
  padding: 1vmin;
  margin: 2.5vmin;
  height: 5vmin;
  font-size: 1vmin;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
  transition-duration: 0.5s;
}

header img {
  left: 0px;
  width: 6vmin;
  margin: 1vmin;
  padding: 1vmin;
  background-color: white;
  border-radius: 100vmax;
  transition-duration: 0.5s;
  box-shadow: 0vmin 0vmax 10px 0px rgba(0, 0, 0, 0.1);
}

header img:hover {
  padding: 2vmin;
  margin: 0;
  transition-duration: 0.5s;
}

li {
  padding: 10vmin;
  margin: 3vmin;
  list-style-type: none;
  text-decoration: none;
  text-align: center;
}

nav a {
  text-align: center;
  font-weight: 500;
  color: darkslategrey;
  padding: 1vmin;
  font-size: 2vmin;
  text-decoration: none;
  margin: 1vmin;
  box-shadow: 0vmin 0vmax 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20vmin;
  padding-left: 1.5vmin;
  padding-right: 1.5vmin;
  transition-duration: 0.5s;
  background-color: white;
}

nav a:hover {
  transition-duration: 1s;
  padding: 2vmin;
  border-radius: 100vw;
  background-color: aliceblue;
  color: aliceblue;
  background-color: darkslategrey;
  box-shadow: 0vmin 0vmax 10px 0px rgba(0, 0, 0, 0.1);
}