.notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FF6060;
  margin-bottom: 50px;
}
.notfound h1 {
  font-size: 288px;
  margin-block: 3vh;
  font-weight: 700;
}
.notfound p {
  font-size: 36px;
  font-weight: 500;
  text-align: center;
}

@media (min-width: 1px) and (max-width: 900px) {
  .notfound h1 {
    font-size: 30vw;
  }
  .notfound p {
    font-size: 5vw;
  }
}