.blochome {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 90vw;
  padding: 0 5vw;
  justify-content: space-between;
}
.blochome .linkimg {
  width: 50vw;
}
.blochome img {
  height: 30vw;
  width: 30vw;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10em;
  overflow: hidden;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  transition-duration: 1s;
}
.blochome img:hover {
  transform: scale(1.1);
  transition-duration: 1s;
  -o-object-position: right;
     object-position: right;
  box-shadow: rgba(0, 0, 0, 0.6) 0em 0 5em;
}

#bh0 {
  background-color: #112038;
  color: white;
}
#bh0 img {
  -o-object-position: left;
     object-position: left;
}
#bh0 img:hover {
  background-color: #c29023;
  -o-object-position: right;
     object-position: right;
}

#bh1 {
  background-color: #c29023;
  color: white;
}

#bh2 {
  background-color: #30739c;
  color: white;
}

#bh3 {
  background-color: #6c2d5e;
  color: white;
}

#bh4 {
  background-color: #4399a3;
  color: white;
}
#bh4 img {
  -o-object-position: center;
     object-position: center;
}
#bh4 img:hover {
  -o-object-position: left;
     object-position: left;
}

.bloctext {
  margin-left: 10em;
  text-align: left;
  color: aliceblue;
  width: 60%;
  padding-right: 10em;
}
.bloctext h1 {
  color: #112038;
  font-size: 5.5em;
  background-color: aliceblue;
  padding: 0.2em 0.5em;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 0.8em;
  margin-left: -0.5em;
}
.bloctext h2 {
  font-size: 4em;
}
.bloctext p {
  font-size: 1.2em;
}
.bloctext a {
  font-size: 2em;
  color: 112038;
  background-color: aliceblue;
  padding: 0.2em 1em;
  border-radius: 0.8em;
  text-decoration: none;
  color: #112038;
  margin-left: -1em;
  transition-duration: 1s;
}
.bloctext a:hover {
  background-color: #112038;
  color: white;
  padding-left: 5em;
  transition-duration: 0.5s;
  border: solid 0.1em aliceblue;
}