content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  margin-bottom: 30px;
}

@media (min-width: 1px) and (max-width: 900px) {
  #a-propos .banner {
    margin-bottom: 30px !important;
  }
  #a-propos .dropdown {
    width: 100%;
  }
}